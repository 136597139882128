import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const LogoWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -75px;
  width: 165px;
  height: 165px;
  border-radius: 100%;
  background-color: #e0e0e0;
`;

const ContentWrapper = styled.div`
  width: 380px;
  height: 630px;
  display: flex;
  flex-direction: column;
  justify-conent: center;
  background-color: #1e1e1e;
`;

const HeaderWrapper = styled.div`
  padding-top: 120px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  padding-left: 24px;
`;

const Title = styled.h2`
  font-size: 20px;
  color: #e0e0e0;
`;

const Period = styled.p`
  font-size: 16px;
  color: #b0b0b0;
  margin-top: 0;
`;

const Content = styled.li`
  font-size: 14px;
  color: #e0e0e0;
  margin-bottom: 12px;
`;

interface CardProps {
  imageUrl: string;
  name: string;
  period: string;
  width: number;
  height: number;
  description: string[];
}

const Card: React.FC<CardProps> = ({
  imageUrl = "",
  name = "",
  period = "",
  width = "",
  height = "",
  description = [],
}) => {
  return (
    <Container>
      <LogoWrapper>
        <img src={imageUrl} alt="logo" width={width} height={height} />
      </LogoWrapper>
      <ContentWrapper>
        <HeaderWrapper>
          <Title>{name}</Title>
          <Period>{period}</Period>
        </HeaderWrapper>
        <MainWrapper>
          {description.map((data, index) => (
            <Content key={index}>{data}</Content>
          ))}
        </MainWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default Card;
