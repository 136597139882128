import React, { useState } from 'react';
import styled from 'styled-components';
import AboutMe from '@pages/AboutMe';
import ContactMe from '@pages/ContactMe';
import Experience from '@pages/Experience';

const AppContainer = styled.div`
  scroll-snap-type: y mandatory;
  height: 100vh;
  overflow-y: hidden;
`;

const SectionWrapper = styled.div`
  scroll-snap-align: start;
  height: 100vh;
  display: flex;
   transition: transform 0.8s ease-in-out
`;

const sections = [<AboutMe />, <Experience />, <div>Projects</div>, <ContactMe />];

const App: React.FC = () => {
  const [currentSection, setCurrentSection] = useState(0);

  const handleScroll = (e: React.WheelEvent) => {
    if (e.deltaY > 0) {
      setCurrentSection((prev) => Math.min(prev + 1, sections.length - 1));
    } else {
      setCurrentSection((prev) => Math.max(prev - 1, 0));
    }
  };

  return (
    <AppContainer onWheel={handleScroll}>
      {sections.map((section, index) => (
        <SectionWrapper key={index} style={{ transform: `translateY(-${currentSection * 100}vh)` }}>
          {section}
        </SectionWrapper>
      ))}

    </AppContainer>
  )
}

export default App;
