import styled, { keyframes } from "styled-components";

const fadeInOut = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

const ScrollArrow = styled.div`
  position: absolute;
  bottom: 10px; 
  left: 50%;
  transform: translateX(-50%);
  width: 48px;
  height: 48px;
  font-size: 2rem;
  color: white;
  animation: ${fadeInOut} 1.5s infinite; 
`;

export default ScrollArrow;
