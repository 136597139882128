import university from "@assets/images/university.png";
import ssafy from "@assets/images/ssafy.png";
import hist from "@assets/images/hist.png";

const data = [
  {
    imageUrl: university,
    name: "B.S. in Computer Engineering",
    period: "2017.03 - 2023.02",
    width: 200,
    height: 156,
    description: ["1", "2", "3", "4", "5"],
  },
  {
    imageUrl: ssafy,
    name: "Software Trainee at SSAFY",
    period: "2023.01 - 2023.10",
    width: 96,
    height: 96,
    description: [],
  },
  {
    imageUrl: hist,
    name: "UAM Developer at HIST",
    period: "2023.10 - 2024.02",
    width: 100,
    height: 100,
    description: [],
  },
];

export default data;
