import React from "react";
import styled, { keyframes } from "styled-components";
import githubImage from "@assets/images/github.png";
import mailImage from "@assets/images/mail.png";

// Keyframes for dynamic gradient animation
const gradientShift = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Dynamic gradient background
const DynamicGradientBackground = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(120deg, #4a90e2, #8e44ad, #1abc9c, #ffd93d);
  background-size: 300% 300%;
  animation: ${gradientShift} 6s infinite linear;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

// Glassmorphism card
const GlassCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 480px;
  padding: 20px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #fff;
  
`;

const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 32px;
`;

const Description = styled.div`
  display: flex;
  width: 200px;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 48px;
`;

const RoundedImage = styled.img`
  width: 96px;
  height: 96px;
  border-radius: 20px;
`;

const ContactMe: React.FC = () => {
  return (
    <DynamicGradientBackground>
      <GlassCard>
        <Title>Contact Me</Title>
        <Description>
          <a href="https://github.com/ischar">
            <RoundedImage
              src={githubImage}
              alt="github"
            />
          </a>
          <a href="mailto:ischar@naver.com">
            <img src={mailImage} width="96" height="96" alt="mail" />
          </a>
        </Description>
      </GlassCard>
    </DynamicGradientBackground>
  );
};

export default ContactMe;
