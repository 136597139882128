import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { Container } from "@components/layout/Container.styles";
import ScrollArrow from "@components/common/ScrollArrow";
import { ReactComponent as ArrowIcon } from "@assets/icons/arrow.svg";

const BackgroundVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
  z-index: -2;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: -1;
`;

const Content = styled.div`
  position: absolute;
  z-index: 1;
  color: white;
  text-align: right;
  padding: 20px;
  position: absolute;
  bottom: 20px;
  right: 60px;
`;

const TitleText = styled.h1`
  font-size: 9rem;
  font-weight: bold;
`;

const AboutMe: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1.5;
    }
  }, []);

  return (
    <Container>
      <BackgroundVideo ref={videoRef} autoPlay loop muted>
        <source src={require("@assets/background.mp4")} type="video/mp4" />
      </BackgroundVideo>
      <Overlay />
      <Content>
        <TitleText>
          Jaeho Cha <br />
          SW Engineer
        </TitleText>
      </Content>
      <ScrollArrow>
        <ArrowIcon width="32px" height="32px" fill="white" />
      </ScrollArrow>
    </Container>
  );
};

export default AboutMe;
