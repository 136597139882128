import React from "react";
import styled from "styled-components";
import Card from "@components/Card";
import data from "@utils/data";
import ScrollArrow from "@components/common/ScrollArrow";
import { ReactComponent as ArrowIcon } from "@assets/icons/arrow.svg";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background-color: #121212;
`;

const Experience: React.FC = () => {
  const cardData = data;

  return (
    <Container>
      {cardData.map((card, index) => (
        <Card
          key={index}
          imageUrl={card.imageUrl}
          name={card.name}
          period={card.period}
          width={card.width}
          height={card.height}
          description={card.description}
        />
      ))}
      <ScrollArrow>
        <ArrowIcon width="32px" height="32px" fill="white" />
      </ScrollArrow>
    </Container>
  );
};

export default Experience;
